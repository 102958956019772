import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LiaArrowLeftSolid } from "react-icons/lia";
import { useSelector } from "react-redux";
import { selectedCar } from "../../utils";
import Skeleton from "react-loading-skeleton";
import dayjs from "dayjs";
import ReparationServices from "../../services/ReparationServices";
import { HiBars3 } from "react-icons/hi2";
import { CLIENT } from "../../pref";
import MainDialog from "../../components/dialog/MainDialog";

const ReparationDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedReparation = location?.state?.reparation;

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)


  const [isOpen, setIsOpen] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState(null);

  const [reparationsListData, setReparationsListData] = useState([] || {});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  // console.log("id params", id)

  const user = useSelector((state) => state.authSlice.userInfo);

  useEffect(() => {
    console.log("selectedReparation", selectedReparation)
    if (selectedReparation === null || selectedReparation === undefined) {
      navigate("/")
    } else {
      loadReparationList()
    }
  }, [selectedReparation]);

  function loadReparationList() {
    setError("")
    setLoading(true)
    ReparationServices
      .getCarReparationList(selectedReparation?.id, car?.id)
      .then(res => setReparationsListData(res?.data))
      .catch(err => setError(err.message))
      .finally(() => setLoading(false))
  }


  const handleCloseDialog = () => {
    setIsOpen(false);
    setSelectedOperation(null);
  };

  const handleOpenDialog = (operation) => {
    console.log("repair operation", operation)
    setSelectedOperation(operation);
    setIsOpen(true);
  };

  function elementTitle(components) {
    let title = "";
    components.forEach((elt, index) => {
      if (elt.component_type === "registered") {
        if (title === "") {
          title = elt?.component?.label + ", "
        } else {
          if (index === components.length - 1) {
            title = title + elt?.component?.label
          } else {
            title = title + elt?.component?.label + ", "
          }
        }
      }
    })

    return title;
  }

  function priceCal(components) {
    let price = 0;
    components.forEach(elt => {
      price = price + elt.amount;
    })

    return price;
  }

  return (
    <>
      <div className="flex  items-center py-3   bg-primary">
        <div
          onClick={() => navigate(-1)}
          className="pl-4 pr-8 cursor-pointer"
        >
          <LiaArrowLeftSolid className="text-white text-2xl" />
        </div>
        <div className="font-myfont text-base md:text-lg text-white">
          Dernière opération - {selectedReparation['label']}
        </div>
      </div>

      {loading && (
        <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-6 px-6 my-4">
          {[1, 2].map(item => {
            return (
              <div key={item}>
                <Skeleton
                  count={4}
                  height={140}
                  className="bg-gray-600"
                />
              </div>
            )
          })}
        </div>
      )}

      {(!loading && error.length !== 0) && (
        <div className="flex-1 h-full flex flex-col justify-center items-center text-red-500 gap-4 my-4">
          <div className="text-center">Une erreur est survenu veuillez réessayer</div>
          <button
            onClick={loadReparationList}
            className="uppercase px-8 py-1.5 rounded hover:bg-red-500/10 active:bg-red-500/20 focus:outline-none border-2 border-red-500">
            réessayer
          </button>
        </div>
      )}

      {(!loading && error.length === 0 && reparationsListData?.data?.length !== 0) && (
        <div className="bg-white flex-1 flex flex-col justify-start items-center overflow-y-auto">
          <div className="w-full flex flex-col px-3 mt-2 mb-4 justify-center items-center">
            <div className="px-2 flex flex-col border-b-2 border-gray-300 w-full py-2 ">
              <div className="text-gray-500 font-semibold">
                {reparationsListData?.data[0]?.system_component?.system?.label}
              </div>
              <div className="flex gap-3">
                <div className="text-sm">
                  Depense total :
                </div>
                <div className="text-sm text-black font-bold uppercase">
                  {`${reparationsListData?.data?.reduce((accumulator, item) => {
                    return accumulator + item.price + item.service_cost;
                  }, 0)} fcfa`}
                </div>
              </div>
            </div>

            <div className="mt-4 w-full grid grid-cols-1 lg:grid-cols-2 gap-3">
              {reparationsListData?.data?.map((item, index) => {
                return (
                  <div
                    onClick={() => handleOpenDialog(item)}
                    key={index}
                    className="w-full flex card bg-white lg:max-w-[450px] h-fit flex-wrap shadow-lg border">
                    <div className="w-full mt-4 px-2 flex flex-col gap-1">
                      <div className="w-full flex flex-row gap-3">
                        <div className='flex-1 text-sm lg:text-base'>
                          Date :
                        </div>
                        <div className='flex-none'>
                          {dayjs(item?.data).format("DD MMM YYYY")}
                        </div>
                      </div>

                      <div className="w-full flex flex-row gap-3">
                        <div className='flex-none text-sm lg:text-base'>
                          Remplacement de :
                        </div>
                        <div className='flex-1'>
                          {elementTitle(item?.components)}
                        </div>
                      </div>

                      {item?.components.length === 1 && (
                        <div className="w-full flex flex-row gap-3">
                          <div className='flex-1 text-sm lg:text-base'>
                            Prix de la piece :
                          </div>
                          <div className='flex-none lowercase'>
                            {`${item?.components[0]?.component ? item?.component?.components[0]?.label : item?.components[0]?.component_type} (${item?.components[0]?.amount} fcfa)`}
                          </div>
                        </div>
                      )}

                      {(item?.components.length > 1 && (
                        <div className="w-full flex flex-row gap-3">
                          <div className='flex-1 text-sm lg:text-base'>
                            Prix de(s) piece(s) :
                          </div>
                          <div className='flex-none flex flex-col lowercase'>
                            {item?.components.map((elt, index) => {
                              return (
                                <div key={index.toString()} className="flex flex-row gap-2 items-center">
                                  <div className="w-2 h-2 rounded-full bg-black" />
                                  <div>{`${elt?.component ? elt?.component?.label : elt?.component_type} (${elt?.amount} fcfa)`}</div>
                                </div>
                              )
                            })}
                            {/*{`${priceCal(item?.components)} fcfa`}*/}
                          </div>
                        </div>
                      ))}

                      <div className="w-full flex flex-row gap-3">
                        <div className='flex-1 text-sm lg:text-base'>
                          Prix de la main d'oeuvre :
                        </div>
                        <div className='flex-none uppercase'>
                          {`${item?.service_cost} fcfa`}
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex justify-end mt-6">
                      <div className="flex items-center gap-4">
                        <div className="text-sm text-gray-700">
                          Prix :
                        </div>
                        <div className="bg-primary py-1 px-6 ml-4 text-white uppercase">
                          {`${item?.price + item?.service_cost} fcfa`}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

          </div>
        </div>
      )}

      {(!loading && error.length === 0 && reparationsListData?.data?.length === 0) && (
        <div className="bg-white flex-1 flex flex-col justify-center items-center overflow-y-auto">
          <div className="w-full flex flex-col gap-2 px-3 mt-2 mb-4 text-center text-gray-500 justify-center items-center">
            <HiBars3 className="text-[150px] text-gray-300" />
            <div className="max-w-md font-semibold">
              Vous n'avez aucune réparation enregistrée
              {/*pour transmission et pneumatique*/}
            </div>
          </div>
        </div>
      )}

      {user?.data?.roles[0]?.name === CLIENT && (
        <button
          className="flex justify-center items-center py-3 bg-primary text-white hover:bg-hoverBleu-200 active:bg-primary"
          onClick={() => navigate("/reparation_new", {
            replace: false,
            state: {
              reparation: selectedReparation,
            }
          })}
        >
          Enregistrer une Réparation
        </button>
      )}
      <MainDialog
        className="max-w-[500px]"
        closeModal={handleCloseDialog}
        isOpen={isOpen}
      >
        {selectedOperation && (
          <div className="p-8 max-w-4xl mx-auto">
      {/* En-tête de la facture */}
      <div className="border-b-2 border-gray-200 pb-8">
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-3xl font-bold text-[#0D506E] mb-2">FACTURE</h1>
            <p className="text-gray-600">
              Date: {dayjs(selectedOperation?.data).format("DD MMM YYYY")}
            </p>
          </div>
          <div className="text-right">
            <h2 className="text-2xl font-bold text-[#0D506E]">
              Véhicule: {car?.plate_number}
            </h2>
            <p className="text-gray-600 mt-2">
              N° Facture: #{selectedOperation?.reference || 'N/A'}
            </p>
          </div>
        </div>
      </div>

      {/* Détails de la réparation */}
      <div className="py-8">
        <h3 className="text-xl font-semibold text-[#0D506E] mb-6">
          Réparation: {selectedReparation.label}
        </h3>

        {/* Main d'œuvre */}
        <div className="bg-gray-50 p-6 rounded-lg mb-8">
          <div className="flex justify-between items-center">
            <span className="text-lg font-medium">Main d'œuvre</span>
            <span className="text-lg font-bold">{selectedOperation?.service_cost.toLocaleString()} FCFA</span>
          </div>
        </div>

        {/* Composants */}
        <div className="mb-8">
          <h4 className="text-lg font-semibold mb-4">Éléments de Remplacement</h4>
          <div className="space-y-3">
            {selectedOperation?.components?.map((status, index) => (
              <div
                key={index}
                className="flex justify-between items-center p-4 bg-white rounded-lg border border-gray-200"
              >
                <span className="font-medium text-gray-800">
                  {status?.component?.label}
                </span>
                <span className="font-bold">
                  {status?.amount.toLocaleString()} FCFA
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Commentaire */}
        {selectedReparation?.commentary && (
          <div className="bg-gray-50 p-6 rounded-lg mb-8">
            <h4 className="text-lg font-semibold mb-2">Commentaire</h4>
            <p className="text-gray-700">
              {selectedReparation?.commentary}
            </p>
          </div>
        )}

        {/* Total */}
        <div className="border-t-2 border-gray-200 pt-6 mt-8">
          <div className="flex justify-between items-center">
            <span className="text-xl font-bold text-[#0D506E]">TOTAL</span>
            <span className="text-2xl font-bold text-[#0D506E]">
              {(selectedOperation?.price + selectedOperation?.service_cost).toLocaleString()} FCFA
            </span>
          </div>
        </div>
      </div>
    </div>
        )}
      </MainDialog>
    </>
  );
};

export default ReparationDetail;
