import requests from "./httpService";

const ReparationServices = {
  getCarReparation: async (vehicleId) => {
    //return requests.get(`/vehicle_systems`);
    return requests.get(`vehicle_systems/vehicles/${vehicleId}`);
  },

  getCarReparationList: async (reparationId, carId) => {
    return requests.get(`/repairs/vehicle/system/${reparationId}/${carId}?include=vehicle`);
  },

  getCarReparationComponents: async (reparationId) => {
    return requests.get(`/system_components/system/${reparationId}`);
  },

  addCarReparation: async (body) => {
    return requests.post(`/repairs`, body);
  },
};
export default ReparationServices;
