import requests from "./httpService";

const BreakDownServices = {
  getAllBreakDown: async (carId) => {
    return requests.get(`/breakdowns/vehicle/${carId}`);
  },

  getAllTwoBreakDown: async (carId) => {
    return requests.get(`/breakdowns/vehicle/all`);
  },

  addBreakDown: async (body) => {
    return requests.post(`/breakdowns`, body);
  },

  updateCarBreakDown: async (body, breakdownId) => {
    return requests.put(`/breakdowns/${breakdownId}`, body);
  }
};

export default BreakDownServices;
