import Maintenance from "../pages/home/maintenance";
import Employee from "../pages/menu/employee";
import Fuel from "../pages/menu/Fuel";
import DepartureArrival from "../pages/menu/departure_arrival";
import Breakdown from "../pages/menu/Breakdown";
import BreakdownNew from "../pages/form/BreakdownNew";
import BreakdownRepair from "../pages/form/Breakdown_repair";
import {Alert} from "@mui/material";
import Statistic from "../pages/menu/statistics";
import Setting from "../pages/menu/Setting";
import Legislation from "../pages/home/legislation";
import Repair from "../pages/home/repair";
import Utilisation from "../pages/home/utilisation";
import AddEmployee from "../pages/form/AddEmployee";
import OperationNew from "../pages/form/OperationNew";
import AddFuel from "../pages/form/AddFuel";
import DocumentNew from "../pages/form/DocumentNew";
import RepairNew from "../pages/form/repair_new";
import ReparationDetail from "../pages/details/reparation_detail";
import CarNew from "../pages/form/Car_new";
import MaintenanceDetail from "../pages/details/maintenance_detail";
import CheckOut from "../pages/form/check_out";
import CheckIn from "../pages/form/check_in";
import AlertPreventive from "../pages/menu/Alert_preventive";
import AlertAdministrative from "../pages/menu/Alert_administrative";

const routesAdmin = [
  {
    path: "/",
    component: Maintenance,
  },
  {
    path: "/employee",
    component: Employee,
  },
  {
    path: "/carburation",
    component: Fuel,
  },
  {
    path: "/chechin_checkout",
    component: DepartureArrival,
  },
  {
    path: "/check_out",
    component: CheckOut,
  },
  {
    path: "/check_in",
    component: CheckIn,
  },
  {
    path: "/breakdown",
    component: Breakdown,
  },
  {
    path: "/breakdown_new",
    component: BreakdownNew,
  },
  {
    path: "/breakdown_repair",
    component: BreakdownRepair,
  },
  {
    path: "/alert",
    component: Alert,
  },
  {
    path: "/statistics",
    component: Statistic,
  },
  {
    path: "/setting",
    component: Setting,
  },
  {
    path: "/setting",
    component: Setting,
  },
  {
    path: "/repair",
    component: Repair,
  },
  {
    path: "/legislation",
    component: Legislation,
  },
  {
    path: "/utilisation",
    component: Utilisation,
  },
  {
    path: "/employee",
    component: Employee,
  },
  {
    path: "/addEmployee",
    component: AddEmployee,
  },
  {
    path: "/operation_new",
    component: OperationNew,
  },
  {
    path: "/reparation_detail",
    component: ReparationDetail,
  },
  {
    path: "/reparation_new",
    component: RepairNew,
  },
  {
    path: "/document_new",
    component: DocumentNew,
  },
  {
    path: "/fuel_new",
    component: AddFuel,
  },
  {
    path: "/car_new",
    component: CarNew,
  },
  {
    path: "/maintenance_detail",
    component: MaintenanceDetail,
  },
  {
    path: "/alert_preventive",
    component: AlertPreventive,
  },
  {
    path: "/alert_administrative",
    component: AlertAdministrative,
  }
];

export default routesAdmin;
