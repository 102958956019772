import Cookies from "js-cookie";
import React, { useContext, useRef, useState } from "react";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { SidebarContext } from "../../context/SidebarContext";
import {selectedCar} from "../../utils";
import {logout} from "../../redux-toolkit/authSlice";
import {USER_INFO} from "../../pref";
import MainDialog from "../dialog/MainDialog";
import EditKmDialog from "../dialog/EditKmDialog";
import dayjs from "dayjs";
import ReparationServices from "../../services/ReparationServices";
import {notifyError, notifySuccess} from "../../utils/toast";
import CarServices from "../../services/CarServices";
import {updateMileage} from "../../redux-toolkit/carManagementSlice";

const Header = ({}) => {
  const { toggleSidebar, setIsUpdate, setNavBar, navBar } = useContext(SidebarContext);
  const dispatch = useDispatch();

  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  // console.log("car ", car)

  const [isOpen, setIsOpen] = useState(false);
  const handleCloseDialog = () => setIsOpen(false)
  const handleOpenDialog = (value) => setIsOpen(true)
  const [loading, setLoading] = useState(false);

  const handleLogOut = () => {
    dispatch(logout());
    Cookies.remove(USER_INFO);
    window.location.replace(`${process.env.REACT_APP_ADMIN_DOMAIN}`);
  };

  function onSubmit (e) {
    setLoading(true);

    const body = {
      "vehicle_id": car?.id,
      "added_mileage": e.mileage,
      "date": dayjs(new Date()).format("YYYY-MM-DD")
    }

    // console.log(body)

    CarServices.updateMileage(body)
      .then((res)=> {
        // console.log(res?.data?.data?.vehicle)
        handleCloseDialog();
        dispatch(updateMileage(res?.data?.data?.vehicle))
        window.location.reload();
        notifySuccess("Le Kilométrage a été mis a jour avec succès!")
      })
      .catch((res) => notifyError(res?.response?.data?.message))
      .finally(()=> setLoading(false))
  }

  if(car === null) {
    return (
        <header className="flex-none z-30 py-2.5 bg-primary shadow-sm">
          <div className="container flex gap-2 items-center justify-start h-[35px] px-4 py-1.5 mx-auto">
            <button
                className="py-1 px-2 -ml-1 rounded-md focus:outline-none block lg:hidden"
                onClick={toggleSidebar}
                aria-label="Menu"
            >
              <i className="w-4 h-4 fa-solid fa-bars text-white"/>
            </button>
            <div className="text-white">
              Accueil
            </div>
          </div>
        </header>
    )
  }

  return (
      <>
        <header className="flex-none z-30 py-2.5 bg-primary shadow-sm">
          <div className="container flex items-center justify-between h-full px-2 mx-auto">
            <div className="flex-1 w-full flex gap-3">
              <button
                  className="py-1 px-2 -ml-1 rounded-md lg:hidden focus:outline-none"
                  onClick={toggleSidebar}
                  aria-label="Menu"
              >
                <i className=" text-white fa-solid fa-bars w-4 h-4"/>
              </button>
              <div className="w-full flex items-center ">
                <img
                    alt="ico"
                    className="h-5"
                    src={`${process.env.REACT_APP_BASE_URL}/${car?.brand?.icon}`}
                />
                <div className="w-full ml-5 text-white ">
                  <p className="w-fit">{car?.plate_number}</p>
                  <p className="text-white/50">{car?.driver === null ? "Non defini" : car?.driver?.name}</p>
                </div>
              </div>
            </div>

            <button
                onClick={handleOpenDialog}
                className={["rounded py-2 px-8 font-semibold focus:outline-none hover:bg-black/20 active:bg-transparent", car?.mileage_alert ? "text-red-600 border-2 border-red-600" : "text-green-600 border-2 border-green-600"].join(" ")}>
              {car?.mileage} KM
            </button>
          </div>
        </header>

        <MainDialog className="max-w-[400px]" closeModal={handleCloseDialog} isOpen={isOpen}>
          <EditKmDialog
            closeModal={handleCloseDialog}
            onSubmit={onSubmit}
            loading={loading}/>
        </MainDialog>
      </>
  );
};

export default Header;
