import React, { useEffect, useState }  from "react";
import FormOperation from "../../components/auth/FormOperationNew";
import Main from "../../layout/Main";
import {useNavigate, useLocation} from "react-router-dom";

const OperationNew = () => {
  const navigate = useNavigate();
    const location = useLocation();

    const selectedOperation = location?.state?.operation;
  
    useEffect(() => {
      console.log("operation ", selectedOperation)
  
    }, [selectedOperation]);

  return (
    <Main selected="empty">
      <div className="flex-1 w-full h-full overflow-y-auto bg-primary flex justify-center border-t-2">
        <div className="max-w-[550px] w-full px-6 md:px-0 pb-4">
          <div className="mt-16 text-center">
            <div className="uppercase text-[#FFFFFF] font-bold">
              enregistrer une opération
            </div>
          </div>
          <div className="mt-16">
            <FormOperation/>
          </div>
          <button
            onClick={() => {
              navigate(`/`);
            }}
            className="inputSecondary"
            type="button">
            annuler
          </button>
        </div>
      </div>
    </Main>
  );
};

export default OperationNew;
