import React, {useEffect, useState} from "react";
import backArrow from "../../images/backArrow.png";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {LiaArrowLeftSolid} from "react-icons/lia";
import MaintenanceServices from "../../services/MaintenanceServices";
import {useSelector} from "react-redux";
import {selectedCar} from "../../utils";
import Skeleton from "react-loading-skeleton";
import dayjs from "dayjs";
import {HiBars3} from "react-icons/hi2";
import {CLIENT} from "../../pref";

const MaintenanceDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedOperation = location?.state?.operation;

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const [operationsListData, setOperationsListData] = useState([] || {});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  // console.log("id params", id)

  const user = useSelector((state) => state.authSlice.userInfo);

  useEffect(() => {
    console.log("selectedOperation ", selectedOperation)
    console.log("selectedOperation type:", selectedOperation['operation_type']['label'])
    if(selectedOperation === null || selectedOperation === undefined || !car?.id){
        navigate("/")
    }else{
        loadMaintenanceList()
    }
  }, [selectedOperation]);

  function loadMaintenanceList () {
    setError("")
    setLoading(true)
    MaintenanceServices
      .getCarOperationList(selectedOperation?.operation_type?.id, car?.id)
      .then(res => setOperationsListData(res?.data))
      .catch(err => setError(err.message))
      .finally(()=> setLoading(false))
  }

  return (
      <>
        <div className="flex  items-center py-3   bg-primary">
          <div
              onClick={() => navigate(-1)}
              className="pl-4 pr-8 cursor-pointer"
          >
            <LiaArrowLeftSolid className="text-white text-2xl"/>
          </div>
          <div className="font-myfont text-base md:text-lg text-white">
            Dernière(s) opération(s) - {selectedOperation['operation_type']['label']}
          </div>
        </div>

        {loading && (
            <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-6 px-6 my-4">
              {[1, 2].map(item => {
                return (
                  <div key={item}>
                    <Skeleton
                        count={4}
                        height={140}
                        className="bg-gray-600"
                    />
                  </div>
                )
              })}
            </div>
        )}

        {(!loading && error.length !== 0) && (
            <div className="flex-1 h-full flex flex-col justify-center items-center text-red-500 gap-4 my-4">
              <div className="text-center">Une erreur est survenu veuillez réessayer</div>
              <button
                  onClick={loadMaintenanceList}
                  className="uppercase px-8 py-1.5 rounded hover:bg-red-500/10 active:bg-red-500/20 focus:outline-none border-2 border-red-500">
                réessayer
              </button>
            </div>
        )}

        {(!loading && error.length === 0 && operationsListData?.data?.length !== 0) && (
            <div className="bg-white flex-1 flex flex-col justify-start items-center overflow-y-auto">
              <div className="w-full flex flex-col px-3 mt-2 mb-4 justify-center items-center">
                <div className="px-2 flex flex-col border-b-2 border-gray-300 w-full py-2 ">
                  <div className="text-gray-500 font-semibold">
                    {operationsListData?.data[0]?.operation_type?.label}
                  </div>
                  <div className="flex gap-3">
                    <div className="text-sm">
                      Depense total :
                    </div>
                    <div className="text-sm text-black font-bold uppercase">
                      {`${operationsListData?.data?.reduce((accumulator, item) => {
                        return accumulator += item.price;
                      }, 0)} fcfa`}
                    </div>
                  </div>
                </div>

                <div className="mt-4 w-full grid grid-cols-1 lg:grid-cols-2 gap-3">
                  {operationsListData?.data?.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className="w-full flex card bg-white lg:max-w-[450px] flex-wrap shadow-lg border">
                          <div className="w-full mt-4 px-2 flex flex-col gap-1">
                            <div className="w-full flex flex-row gap-3">
                              <div className='flex-1 text-sm lg:text-base'>
                                Date :
                              </div>
                              <div className='flex-none'>
                                {dayjs(item?.data).format("DD MMM YYYY")}
                              </div>
                            </div>

                            <div className="w-full flex flex-row gap-3">
                              <div className='flex-1 text-sm lg:text-base'>
                                Kilometratrage a la date :
                              </div>
                              <div className='flex-none'>
                                {`${item?.current_mileage} km`}
                              </div>
                            </div>

                            <div className="w-full flex flex-row gap-3">
                              <div className='flex-1 text-sm lg:text-base'>
                                Durée du Kilometratrage :
                              </div>
                              <div className='flex-none'>
                                {`${item?.granted_mileage} km`}
                              </div>
                            </div>
                          </div>
                          <div className="w-full flex justify-end mt-6">
                            <div className="flex items-center gap-4">
                              <div className="text-sm text-gray-700">
                                Prix :
                              </div>
                              <div className="bg-primary py-1 px-6 ml-4 text-white uppercase">
                                {item?.price} {` fcfa`}
                              </div>
                            </div>
                          </div>
                        </div>
                    )
                  })}
                </div>

              </div>
            </div>
        )}

        {(!loading && error.length === 0 && operationsListData?.data?.length === 0) && (
          <div className="bg-white flex-1 flex flex-col justify-center items-center overflow-y-auto">
            <div
                className="w-full flex flex-col gap-2 px-3 mt-2 mb-4 text-center text-gray-500 justify-center items-center">
              <HiBars3 className="text-[150px] text-gray-300"/>
              <div className="max-w-md font-semibold">
                Vous n'avez aucune opération enregistrée
                {/*pour transmission et pneumatique*/}
              </div>
            </div>
          </div>
        )}

        {user?.data?.roles[0]?.name === CLIENT && (
            <button
                className="flex justify-center items-center py-3 bg-primary text-white hover:bg-hoverBleu-200 active:bg-primary"
                onClick={() => navigate("/operation_new", {
                    replace: false,
                    state: {
                        operation: selectedOperation,
                    },
                })}
            >
                Enregistrer une opération
            </button>
        )}
      </>
  );
};

export default MaintenanceDetail;
