import React, {useEffect} from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Main from "../../layout/Main";
import {useSelector} from "react-redux";
import MaintenanceServices from "../../services/MaintenanceServices";
import {selectedCar} from "../../utils";
import {MdOutlineWarning, MdOutlineWarningAmber} from "react-icons/md";
import Skeleton from "react-loading-skeleton";

const Maintenance = () => {
  const navigate = useNavigate();

  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const [operationsData, setOperationsData] = useState([] || {});
  const [error, setError] = useState("");
  const [loadingOperations, setLoadingOperations] = useState(false);

  useEffect(() => {
    if(car !== null){
      loadMaintenance()
    }
  }, [car]);

  function loadMaintenance () {
    setError("")
    setLoadingOperations(true)
    MaintenanceServices
      .getCarOperation(car?.id)
      .then(res => setOperationsData(res?.data))
      .catch(err => setError(err.message))
      .finally(()=> setLoadingOperations(false))
  }

  return (
    <Main selected="Entretien">
      <div className="flex-1 h-full overflow-y-auto">
        <div className="flex h-full text-white justify-start flex-col px-2 md:px-6">
          <div className="flex-none text-center my-2 p-2 md:p-4 rounded-lg bg-white shadow-lg border-gray-300 border">
            <div className="uppercase text-primary font-bold text-md md:text-xl">
              element d'entretien
            </div>
            <p className="text-gray-500 text-sm md:text-base">
              Enregistrez systématiquement chaque opération d'entretien du
              vehicule , avec le kilométrage a data afin que le système puisse
              vous rappelez les échéances
            </p>
          </div>

          {loadingOperations && (
            <div className="cards grid grid-cols-1 md:grid-cols-2 gap-6 md:px-6">
              {[1, 2].map(item => {
                return (
                  <div key={item}>
                    <Skeleton
                        count={4}
                        height={140}
                        className="bg-gray-600"
                    />
                  </div>
              )
            })}
            </div>
          )}

          {(!loadingOperations && error.length !== 0) && (
            <div className="h-full flex flex-col justify-center items-center text-red-500 gap-4 my-4">
              <div className="text-center">Une erreur est survenu veuillez réessayer</div>
              <button
                  onClick={loadMaintenance}
                  className="uppercase px-8 py-1.5 rounded hover:bg-red-500/10 active:bg-red-500/20 focus:outline-none border-2 border-red-500">
                réessayer
              </button>
            </div>
          )}

          {(!loadingOperations && error.length === 0) && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-4">
                {operationsData?.data?.map(operation => {
                  return (
                    <div
                      key={operation?.id}
                      onClick={() => navigate("/maintenance_detail", {
                        replace: false,
                        state: {
                          operation: operation,
                          
                        },
                      })}
                      className="card cursor-pointer hover:bg-slate-200 active:bg-slate-300 rounded-lg p-2 flex items-center "
                    >
                      <div className="w-[48px] h-[48px] md:w-[70px] md:h-[70px]">
                        <img
                          alt="ico"
                          className="w-full"
                          src={`${process.env.REACT_APP_BASE_URL}/${operation?.operation_type?.thumburl}`}
                        />
                      </div>
                      <div className="ml-2 border-gray-300 border-b-2 pb-3 w-[80%]">
                        <div className="text-[#0D506E] text-base md:text-xl font-bold">
                          {operation?.operation_type?.label}
                        </div>
                        <div className="text-gray-500 text-sm font-semibold">
                          {operation?.operation_type?.operation}
                        </div>
                        <p className="text-sm text-primary font-semibold">
                          {operation?.total_operations} {`${operation?.total_operations > 1 ? "operations" : "operation"}`}
                        </p>
                        <p className={["text-sm font-semibold", operation?.remaining_mileage <= 0 ? "text-red-500" : "text-gray-600"].join(" ")}>
                          {operation?.remaining_mileage <= 0 ? "Dépassement : " : "kilométrage restant : "}
                          {Math.abs(operation?.remaining_mileage)}
                        </p>
                        <p className="text-sm text-gray-600 font-semibold">
                          {operation?.operation_type?.short_advice}
                        </p>
                        <p className="text-[#0D506E] text-sm md:text-md  font-bold">
                        nombre d'entretien(s): {operation?.total_operations}
                      </p>

                      <p className="text-[#0D506E] text-sm md:text-md  font-bold">
                        budget(s): {operation?.total_spent??0} XAF
                      </p>
                      </div>
                      <div className="text-[#F44538] text-[30px]">
                        {(operation?.remaining_mileage > 0 && operation?.alert) && (
                          <MdOutlineWarningAmber className=""/>
                        )}
                        {(operation?.remaining_mileage <= 0) && (
                          <MdOutlineWarning className=""/>
                        )}
                      </div>
                    </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </Main>
  );
};

export default Maintenance;
