import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LiaArrowLeftSolid } from "react-icons/lia";
import { useSelector } from "react-redux";
import { MdOutlineWarning, MdOutlineWarningAmber } from "react-icons/md";
import MainDialog from "../../components/dialog/MainDialog";
import AlertServices from "../../services/AlertServices";

const AlertPreventive = () => {
    const navigate = useNavigate();
    const [alertListData, setAlertListData] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOperation, setSelectedOperation] = useState(null);

    React.useEffect(() => {
        console.log("⚡ useEffect triggered - Starting loadFuelList");
        loadFuelList();
    }, []);

    function loadFuelList() {
        setError("");
        setLoading(true);
        AlertServices
            .getAlertPreventive()
            .then(res => {
                setAlertListData(res?.data?.data);
                console.log("🔄 Updated alertListData:", res?.data?.data);
            })
            .catch(err => {
                console.error("❌ Error message:", err.message);
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
                console.log("🏁 Loading completed");
            });
    }

    const handleCloseDialog = () => {
        setIsOpen(false);
        setSelectedOperation(null);
    };

    const handleOpenDialog = (operation) => {
        setSelectedOperation(operation);
        setIsOpen(true);
    };

    return (
        <>
            <div className="flex items-center py-3 bg-primary">
                <div
                    onClick={() => navigate(-1)}
                    className="pl-4 pr-8 cursor-pointer"
                >
                    <LiaArrowLeftSolid className="text-white text-2xl" />
                </div>
                <div className="font-myfont text-base md:text-lg text-white">
                    Alerte(s) Preventive(s)
                </div>
            </div>

            <div className="overflow-y-auto max-h-screen">
                {(alertListData && error.length === 0) && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-4">
                        {alertListData?.map(operation => (
                            <div
                                key={operation?.id}
                                onClick={() => handleOpenDialog(operation)}
                                className="card cursor-pointer hover:bg-slate-200 active:bg-slate-300 rounded-lg p-2 flex items-center"
                            >
                                <div className="w-[48px] h-[48px] md:w-[70px] md:h-[70px]">
                                    <img
                                        alt="ico"
                                        className="w-full"
                                        src={`${process.env.REACT_APP_BASE_URL}/${operation?.logo}`}
                                    />
                                </div>
                                <div className="ml-2 border-gray-300 border-b-2 pb-3 w-[80%]">
                                    <div className="text-[#0D506E] text-base md:text-xl font-bold">
                                        {operation?.plate_number}
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                )}
            </div>

            <MainDialog
                className="max-w-[500px]"
                closeModal={handleCloseDialog}
                isOpen={isOpen}
            >
                {selectedOperation && (
                    <div className="p-4">
                        <div className="flex items-center mb-4">
                            <img
                                alt="vehicle"
                                className="w-16 h-16"
                                src={`${process.env.REACT_APP_BASE_URL}/${selectedOperation?.logo}`}
                            />
                            <div className="ml-4">
                                <h2 className="text-xl font-bold text-[#0D506E]">
                                    {selectedOperation?.plate_number}
                                </h2>
                            </div>
                        </div>

                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold">Alerte(s)</h3>
                            {selectedOperation?.operation_statuses?.map((status, index) => (
                                <div
                                    key={index}
                                    className="p-3 bg-gray-50 rounded-lg border border-gray-200 flex"
                                //onClick={() => navigate("/operation_new", {
                                //  replace: true,
                                // state: {
                                //   operation: status,
                                //},
                                //})}
                                >
                                    <div>
                                        <div className="font-medium text-gray-800">
                                            {status?.operation_type?.label}
                                        </div>
                                        <div className="text-sm text-gray-600 mt-1">
                                            Conseil: {status?.operation_type?.advice}
                                        </div>

                                        <div className="text-sm text-gray-600 mt-1">
                                            Action: {status?.operation_type?.operation}
                                        </div>
                                    </div>

                                    <div className="text-[#F44538] text-[30px]">
                                        {(status?.alert) && (
                                            <MdOutlineWarning  className="" />
                                        )}
                                        {(status?.alert && status?.previsional_alert) && (
                                            <MdOutlineWarningAmber className="" />
                                        )}
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </MainDialog>
        </>
    );
};

export default AlertPreventive;