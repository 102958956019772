import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useAsync from "./useAsync";
import AuthServices from "../services/AuthServices";
import {useDispatch, useSelector} from "react-redux";
import CarServices from "../services/CarServices";
import {notifyError, notifySuccess} from "../utils/toast";
import axios from "axios";
import {addNewCar, setCarAction} from "../redux-toolkit/carManagementSlice";
import {useNavigate} from "react-router-dom";
import {selectedCar} from "../utils";

const useCarNew = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authSlice.userInfo);
  const cars = useSelector((state) => state.carManagementSlice.data);
  const car = selectedCar(cars)

  const {
    loading: loadingDriver,
    data: driverData,
    error: errorDriver
  } = useAsync(() => AuthServices.getAllDriver(user?.data?.uuid));

  const {
    loading: loadingCarType,
    data: carTypeData,
    error: errorCarType
  } = useAsync(() => CarServices.getCarType());

  const {
    loading: loadingEngineType,
    data: engineTypeData,
    error: errorEngineType
  } = useAsync(() => CarServices.getEngineType());

  const {
    loading: loadingCarTransmission,
    data: carTransmissionData,
    error: errorCarTransmission
  } = useAsync(() => CarServices.getCarTransmission());

  const {
    loading: loadingCarBrand,
    data: carBrandData,
    error: errorCarBrand
  } = useAsync(() => CarServices.getCarBrand());

  const carColors = [
      "jaune",
      "noire",
      "Grise",
      "blanche",
      "rouge",
      "verte",
      "bleu",
      "orange",
      "marron",
      "autre"
  ]

  const [loading, setLoading] = useState(false);
  const initialValues = {
    driver: "",
    cartType: "",
    engineType: "",
    transmissionType: "",
    carBrand: "",
    carModel: "",
    carColor: "",
    carImmatriculation: "",
    carDate: "",
    carMileage: "",
    carFuel: "",
    carPrice: "",
  };

  const validationSchema = yup.object().shape({
    cartType: yup.string().required(),
    engineType: yup.string().required(),
    transmissionType: yup.string().required(),
    carBrand: yup.string().required(),
    carModel: yup.string().required(),
    carColor: yup.string().required(),
    carImmatriculation: yup.string().required(),
    carDate: yup.string().required(),
    carMileage: yup.string().required(),
    carPrice: yup.string().required(),
    carFuel: yup.string().required()
  });

  const onSubmit = (e) => {
     console.log("request: ",e);
    setLoading(true);

    const body = {
      "brand_id": e.carBrand,
      "engine_id": e.engineType,
      "transmission_id": e.transmissionType,
      "model": e.carModel,
      "color": e.carColor,
      "initial_mileage": e.carMileage,
      "price": e.carPrice,
      "last_fuel_qty": e.carFuel,
      "commissioning_date": e.carDate,
      "plate_number": e.carImmatriculation,
      "type": e.cartType
    }

    if (e.driver !== "") {
      body['driver_id'] = e.driver;
    }

    // axios.get('https://services.gveo.org/sanctum/csrf-cookie').then((res)=> {
    //   console.log(res?.headers)
    // });
    CarServices.addCar(body)
      .then((res)=> {
        if(res?.data?.success){
          console.log(res?.data?.data)
          dispatch(addNewCar(res?.data?.data))

          if(car === null){
            dispatch(setCarAction(cars.length - 1))
            navigate("/")
          }
          console.log("car added")
          navigate("/")
          notifySuccess("Véhicule crée avec succès")
        }else{
         notifyError(res?.data?.message)
         console.log("car added error")
        }
      })
      .catch((res) => notifyError(res?.response?.data?.message))
      .finally(()=> setLoading(false))
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return {
    formik,
    loading,
    driverData,
    carTypeData,
    engineTypeData,
    carTransmissionData,
    carBrandData,
    carColors,
  };
};
export default useCarNew;
