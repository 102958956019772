import requests from "./httpService";

const AlertServices = {

  getAlertAdministration: async () => {
    return requests.get( `/document-status/alerts-grouped-by-vehicle`);
  },

  getAlertPreventive: async () => {
    return requests.get( `/operation-status/alerts-grouped-by-vehicle`);
  },
};
export default AlertServices;
